<template lang="pug">
  .booking-details
    ArrowBack(@back="$emit('back')")
    AppOverlayLoader(:state="loading")
    .container-fluid
      .row
        Title(:title="$t('ota_management.booking_confirmation.form.reception.title')")
      .row
        FormPartial(
          prefix="ota_management.booking_confirmation.form.reception"
          :item="reservation.reception"
        )
      .row
        Title(:title="$t('ota_management.booking_confirmation.form.details.title')")
      .row
        FormPartial(
          prefix="ota_management.booking_confirmation.form.details"
          :item="reservation.details"
        )
      .row
        Title(:title="$t('ota_management.booking_confirmation.form.notes.title')")
      .row
        ReservationFeeBreakdown(:info="reservation.feeBreakdown")
      .row
        FormPartial(
          prefix="ota_management.booking_confirmation.form.notes"
          :item="reservation.notes"
        )
</template>

<script>
  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  // store modules
  import bookingConfirmationModule from "@/config/store/ota_management/booking_confirmation"

  const bookingConfirmationMixin = withStoreModule(bookingConfirmationModule, {
    name: "otaManagementBookingConfirmationMixin",
    readers: {
      reservation: "item",
      loading: "loading"
    },
    actions: {
      fetchReservation: "FETCH_ITEM"
    }
  })

  export default {
    components: {
      Title: () => import("@/components/CompanySystem/Title"),
      FormPartial: () => import("@/components/CompanySystem/FormPartial"),
      ReservationFeeBreakdown: () => import("./ReservationFeeBreakdown"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      ArrowBack: () => import("@/components/CompanySystem/ArrowBack")
    },

    mixins: [bookingConfirmationMixin],

    props: {
      reservationId: {
        type: Number,
        required: false
      }
    },

    mounted() {
      this.fetchReservation(this.reservationId)
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"
</style>
